import { graphql, Link, withPrefix } from 'gatsby';
import * as React from 'react';

import { A } from '../components/a';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { Location, SiteMetadata } from '../models';
import EmailPopup from '../components/email-popup';

import {Helmet} from 'react-helmet'


interface TagLike {
  name: string;
  count: string;
}

interface PageReference {
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
  };
}

interface PageQueryResult {
  site: SiteMetadata;

  markdownRemark: MarkdownRemark;
  allMarkdownRemark: {
    nodes: MarkdownRemark[];
  };
  allTag: {
    nodes: TagLike[];
  };
  allCategory: {
    nodes: TagLike[];
  };
  previous: PageReference;
  next: PageReference;
}

interface MarkdownRemark {
  id: string;
  excerpt: string;
  html: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
    date: string;
    description: string;
    author: string;
    tags: string[];
    categories: string[];
    feed: string;
    coverImage?: {
      publicURL: string;
      absolutePath: string;
    };
  };
}

interface Props {
  data: PageQueryResult;
  location: Location;
}

const BlogPostTemplate: React.FunctionComponent<Props> = ({ data, location }: Props) => {
  const latestPosts = data.allMarkdownRemark.nodes;
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;
  


  const siteAbsoluteURL = data.site.siteMetadata.siteUrl + data.markdownRemark.fields.slug;
  const imageAbsoluteURL = post.frontmatter.coverImage ? 
      data.site.siteMetadata.siteDomain + post.frontmatter.coverImage.publicURL :
      '';

  return (
  
    <div id="main_content">
      <Helmet>
        <meta property="og:title" content={post.frontmatter.title}></meta>
        <meta property="og:type" content="article" />
        {post.frontmatter.coverImage && (
          <meta property="og:image" content={imageAbsoluteURL}></meta>
        )}
          
        <meta property="og:url" content={siteAbsoluteURL}></meta>
        <meta property="og:description" content={post.frontmatter.title}></meta>
        <meta property="og:site_name" content="Skillgo"></meta>
        <meta name="twitter:title" content={post.frontmatter.title}></meta>
        <meta name="twitter:description" content={post.frontmatter.title}></meta>
        <meta name="twitter:image" content={imageAbsoluteURL}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
      <Navbar dark={true} />
      <EmailPopup/>

      <section className="page-banner blog-details-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <ul className="post-meta color-theme">
              <li>
                <A>{post.frontmatter.date}</A>
              </li>
            </ul>
            <h1 className="page-title">{post.frontmatter.title}</h1>

            <ul className="post-meta">
              <li>
                <span>By</span> {post.frontmatter.author}
              </li>
              <li className="post-meta-categories">
                {post.frontmatter.categories.map(category => (
                  <Link key={category} to={`/${post.frontmatter.feed}/categories/${category}`}>
                    {category}
                  </Link>
                ))}
              </li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <section className="blog-single">
        <div className="container pb-120">
          <div className="row">
            <div className="col-md-8">
              <div className="post-wrapper">
                <article className="post post-signle">
                  {post.frontmatter.coverImage && (
                    <div className="feature-image">
                      <img src={post.frontmatter.coverImage.publicURL} alt="" />
                    </div>
                  )}
                  <div className="blog-content">
                    <div dangerouslySetInnerHTML={{ __html: post.html }}></div>

                    <div className="tagcloud">
                      <span>Tags:</span>
                      {post.frontmatter.tags.map(tag => (
                        <Link key={tag} to={`/${post.frontmatter.feed}/tags/${tag}`}>
                          {tag}
                        </Link>
                      ))}
                    </div>
                  </div>
                </article>
                <div className="blog-share mb-5">
                  <div className="share-title">
                    <p>Share:</p>
                  </div>

                  <ul className="share-link">
                    <li>
                    <a href={'https://www.facebook.com/share.php?u='+siteAbsoluteURL} target="_blank" >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                        <a href={'https://twitter.com/share?url='+siteAbsoluteURL} target="_blank">
                          <i className="fab fa-twitter"></i>
                       </a>
                    </li>
                    <li>
                      <a href={'https://www.linkedin.com/sharing/share-offsite/?url='+siteAbsoluteURL} target="_blank">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="sidebar">
                <SidebarCategories categories={data.allCategory.nodes} />
                <SidebarLatestPosts posts={latestPosts} />
                <SidebarTags tags={data.allTag.nodes} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
        siteUrl
        siteDomain
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        categories
        tags
        feed
        coverImage {
          publicURL
          absolutePath
        }
      }
      fields {
        slug
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { feed: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
        }
      }
    }
    allTag(filter: { feed: { eq: "blog" } }, sort: { fields: [count], order: DESC }, limit: 7) {
      nodes {
        name
        count
      }
    }
    allCategory(
      filter: { feed: { eq: "blog" } }
      sort: { fields: [count], order: DESC }
      limit: 7
    ) {
      nodes {
        name
        count
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

interface SidebarLatestPostsProps {
  posts: MarkdownRemark[];
}

function SidebarLatestPosts({ posts }: SidebarLatestPostsProps) {
  return (
    <div id="gp-posts-widget-2" className="widget gp-posts-widget">
      <h2 className="widget-title">Latest Posts</h2>
      <div className="gp-posts-widget-wrapper">
        {posts.map(post => (
          <LatestPostsItem key={post.fields.slug} post={post} />
        ))}
      </div>
    </div>
  );
}

interface LatestPostsItemProps {
  post: MarkdownRemark;
}

function LatestPostsItem({ post }: LatestPostsItemProps) {
  return (
    <div className="post-item">
      <div className="post-widget-info">
        <h5 className="post-widget-title">
          <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
        </h5>
        <span className="post-date">{post.frontmatter.date}</span>
      </div>
    </div>
  );
}

interface SidebarCategoriesProps {
  categories: TagLike[];
}

function SidebarCategories({ categories }: SidebarCategoriesProps) {
  return (
    <div id="categories" className="widget widget_categories">
      <h2 className="widget-title">Categories</h2>
      <ul>
        {categories.map(c => (
          <li key={c.name}>
            <Link to={`/blog/categories/${c.name}`}>
              {c.name} <span className="count">({c.count})</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

interface SidebarTagsProps {
  tags: TagLike[];
}

function SidebarTags({ tags }: SidebarTagsProps) {
  return (
    <aside id="tags" className="widget widget_tag">
      <h3 className="widget-title">Popular Tags</h3>
      <div className="tagcloud">
        {tags.map(t => (
          <Link key={t.name} to={`/blog/tags/${t.name}`}>
            {t.name}
          </Link>
        ))}
      </div>
    </aside>
  );
}
